import liff from '@line/liff'
import axios from 'axios'
let accessToken = null
let lineUserId = null
export default {
    async getLineIdForLiff (liffId) {
        await liff.init({ liffId }).catch(err => { throw err })
        const profile = await liff.getProfile()
        return profile.userId
    },

    async openWindow (liffId, url) {
        await liff.init({ liffId }).catch(err => { throw err })
        await liff.openWindow({ url })
    },

    async closeWindow (liffId) {
        await liff.init({ liffId }).catch(err => { throw err })
        await liff.closeWindow()
    },

    getHeaders () {
        return {
            baseURL: process.env.VUE_APP_BACKEND,
            headers: {
                Authorization: accessToken
            }
        }
    },

    async get (url) {
        return await axios.get(url, this.getHeaders())
    },

    async post (url, body) {
        return await axios.post(url, body, this.getHeaders())
    },

    isInLineBrowser () {
        const { userAgent } = navigator
        // web open in LINE App Liff or LINE in app browser (desktop)
        return liff.isInClient() || (!liff.isInClient() && userAgent.includes('Line'))
    },

    setAccessToken (token) {
        if (Array.isArray(token)) {
            accessToken = token[0].replaceAll(' ', '+')
        } else {
            accessToken = token.replaceAll(' ', '+')
        }
    },

    setLineUserId (userId) {
        lineUserId = userId
    },

    getLineUserId () {
        return lineUserId
    }
}

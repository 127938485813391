export default{
    title:'บันทึกเวลาขาดเรียน',
    titleContent:'คำแนะนำ',
    firstContent:'หากต้องการแก้ไขหรือบันทึกเวลาเรียนย้อนหลัง สามารถทำรายการบนเว็ปไซต์ School Buddy เมนู “บันทึกเวลาขาดเรียน”',
    secondContent:'กรณี ณ วันนั้นผู้สอนไม่ได้ทำการบันทึกผล ระบบจะบันทึก',
    thirdContent:'สถานะนักเรียน “มา” โดยอัตโนมัติ',
    absent:'ขาดเรียน',
    person:'คน',
    courseNotShow:'วิชาที่ไม่แสดงบนตารางสอน',
    notFound:'ไม่พบตารางสอนวันนี้',
    notPeriod:{
        title:'ไม่สามารถบันทึกผลได้',
        content1:'ระบบบันทึกเวลาขาดเรียน จะสามารถใช้งานได้ ในช่วง',
        content2:'เปิดภาคเรียน - วันส่งผลรอบปกติ'
    },
    noTeaching:{
        title:'ไม่มีตารางสอนในภาคเรียนนี้',
        content1:'คุณไม่สามารถเข้าใช้งานฟังก์ชั่นนี้ได้',
        content2:'เนื่องจากไม่ได้รับสิทธิ์การเข้าถึง'
    },  
    networkErr:{
        title:'ขออภัย ไม่สามารถดำเนินการได้',
        content:'เนื่องจากการเชื่อมต่ออินเทอร์เน็ตขัดคล่อง โปรดทำรายการใหม่อีกครั้ง'
    },
    close:'ปิด'
}